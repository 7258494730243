/*  This file is part of ql_rest, a free-software/open-source library
    for utilization of QuantLib over REST */

import { useEffect, useState } from 'react';
import React from 'react';
import PricerHelper from '../helpers/PricerHelper'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { createContext, useCallback } from 'react';

const { forwardRef, useRef, useImperativeHandle } = React;

const StockPanel = React.forwardRef ((props, ref) => {

  const stockGridRef = React.useRef();
  const [stockPrices, setStockPrices] = useState();
  const [marketData, setMarketData] = useState();
  const [selectedInstrument, setSelectedInstrument] = useState();
  const [stockPriceUpdate, setStockPriceUpdate] = useState();



  const stockGridColumnDefs = [
    {
      headerName : 'S&P 100',
      children: [
        { field: 'ticker', header:'Symbol', sortable: true, flex:1, cellStyle: { 'fontWeight': 'bold' }, sortable: true, sort: "asc"},
        { field: 'Price',  sortable: false, width:70,
          cellStyle: {}, editable: true, cellStyle: { 'textAlign': 'right'},
          valueParser: params => isNaN(params.newValue) ? params.newValue : parseFloat(params.newValue),
          valueFormatter: params => {
                if (!isNaN(params.value))
                 return parseFloat(params.value).toFixed(2);
              } },
        { field: 'PriceChange', headerName:'PxChg',  sortable: true, width:80,cellStyle: { 'textAlign': 'right'},

          cellRenderer: params => {
             // put the value in bold;
              if ( params.value == undefined )
                return

               if ( params.value >= 0.0 )
               {
                 return ( <div>
                      <span style={{color: "rgb(152,195,121)"}}>  {params.value} </span>
                      </div> );
               } else {
                  return ( <div>
                       <span style={{color: "rgb(224,108,118)"}}> {params.value} </span>
                       </div> );
               }
             }
        },
        { field: 'PriceChangePercent', headerName:'PxChg %', sortable: true, width:80,cellStyle: { 'textAlign': 'right'},
        cellRenderer: params => {
           // put the value in bold;
            if ( params.value == undefined )
              return

             if ( params.value >= 0.0 )
             {
               return ( <div>
                    <span style={{color: "rgb(152,195,121)"}}>  {params.value} % </span>
                    </div> );
             } else {
                return ( <div>
                     <span style={{color: "rgb(224,108,118)"}}> {params.value} % </span>
                     </div> );
             }
           }
        },
        { field: 'dividend', headerName:'Dividend',  sortable: true,width:70,
          cellStyle: {}, editable: true, cellStyle: { 'textAlign': 'right'},
          valueParser: params => isNaN(params.newValue) ? params.newValue : parseFloat(params.newValue),


                cellRenderer: props => {
                  if (!isNaN(props.value))
                  {
                    return `${(props.value)}`;
                  };
                },

                valueFormatter: params => {
                      if (!isNaN(params.value))
                       return parseFloat(params.value).toFixed(2);
                    } },

          ]}
  ];

  useEffect(() => {
    if ( props.symbols == undefined ) return;

    if ( props.realTimePrice != undefined )
    {
      var priced_symbols = []
      props.symbols.forEach((item, i) => {
        var priced_symbol = {}
        priced_symbol = {...item}

        var latest_price = props.realTimePrice[priced_symbol['ticker']]

        if ( latest_price !== undefined )
        {
            priced_symbol['Price'] = latest_price['market_data']['day']['l']
            priced_symbol['PriceChange'] = latest_price['market_data']['todaysChange'].toFixed(2)
            priced_symbol['PriceChangePercent'] = latest_price['market_data']['todaysChangePerc'].toFixed(2)
        }

        priced_symbols.push(priced_symbol)

      });

      setStockPrices(priced_symbols)

    } else {

      var tickers = []

      props.symbols.forEach((instrument_data, i) => {
        tickers.push({'ticker':instrument_data['ticker'],'dividend':instrument_data['dividend']})
      });


      setStockPrices(tickers);
    }

  },  [props.symbols, props.realTimePrice]);


    useEffect(() => {

      if ( stockPrices != undefined )
      {
        var market_data = {}
        stockPrices.forEach((instrument, i) => {
          market_data[instrument['ticker']] = instrument
        });
        //setMarketData(market_data)
        stockGridRef.current.api.forEachNode( node=> {
        if ( node.rowIndex == 0 ) {
          node.setSelected(true);
          }
        });

        setMarketData(market_data)

        //props.onMarketDataChange(market_data)
      }

    }, [stockPrices]);


    useEffect(() => {

      if ( selectedInstrument!=undefined)
      {
        //var instrumentPrice = marketData[selectedInstrument['ticker']]
        props.onInstrumentChange(selectedInstrument)
        //props.onMarketDataChange(marketData)
      }

    }, [selectedInstrument]);

    useEffect(() => {

      if ( marketData != undefined )
      {
        if ( stockPriceUpdate !== undefined )
        {
          marketData[stockPriceUpdate.ticker] = stockPriceUpdate
        }

        props.onMarketDataChange(JSON.parse(JSON.stringify(marketData)))
        //marketData['']
        //props.onMarketDataChange(marketData)
      }

    }, [marketData, stockPriceUpdate]);


  const stockGridOptions = {

    rowSelection: 'single',
    getRowHeight: (params) => 25,

    onRowSelected(event) {

      if ( !event.node.selected )
        return;

      if ( event.data.Price !== undefined )
      {
        setSelectedInstrument({ 'ticker':event.data.ticker, 'name':event.data.name })
      }
      /*props.getVols(event.data);*/
    },

    onGridReady: event => {
      props.gridReadyCallback();
    }
  }

  const onCellEditingStopped = useCallback((updated_price) =>
  {
      //setUpdatedPrice(updated_price)
      var price_change = JSON.parse(JSON.stringify(updated_price.data))
      setStockPriceUpdate(price_change)
      //props.underlyingPriceChange( updated_price.data );
  }, []);

   return (
      <div>
        <div className="App-Row">
            <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"35vh", width: "100%", display: "inline-block", margin: "auto", padding:"5px"}}>
            <AgGridReact
                      rowData={stockPrices}
                      columnDefs={stockGridColumnDefs}
                      gridOptions={stockGridOptions}
                      onCellEditingStopped={onCellEditingStopped}
                      ref={stockGridRef}>
            </AgGridReact>
            </div>
          </div>
      </div>
     )
  });

export default StockPanel;
