import React from 'react'
import Plot from 'react-plotly.js';

import { useEffect, useState } from 'react';

//import Helpers from './Helpers';


const { forwardRef, useRef, useImperativeHandle } = React;

const VolSurface = React.forwardRef ((props, ref) => {

  const chartRef = useRef();

  const [data, setData] = useState();
  const [layout, setLayout] = useState();

  const handleClick = (event) => {
    if (event.points) {
      event.points.forEach((point) => {
        console.log(`Clicked point:`, {
          x: point.x,
          y: point.y,
          data: point });

          var setup_options_panel = {}
          setup_options_panel['Strike'] = point.x;
          setup_options_panel['expiration_date'] = point.y;

          props.setupOptionsPanel(setup_options_panel);

      });
    }
  };

  useEffect(() => {

    if ( props.vol_surface !== undefined )
    {
      console.log(props.vol_surface)

      var diviser = parseFloat(props.vol_surface['diviser'])

      delete props.vol_surface.diviser

      var expirations = Object.keys(props.vol_surface)
      var strikes = Object.keys(props.vol_surface[expirations[0]])
      var vols = []

      expirations.forEach((expiration_date, i) => {
        var expiration_vols = []
        strikes.forEach((strike, i) => {
            var vol = parseFloat(props.vol_surface[expiration_date][strike])
            expiration_vols.push(vol)
        });
        vols.push(expiration_vols)
      });

      const adjusted_strikes = [... new Set([...strikes].map(num => num / diviser))];

      const surface_data = [
        {
          title:'test',
          marker: { colors: ["#5eba7d", "#1565c0"] },
          color: "#fff",
            x: adjusted_strikes,
            y: [...expirations],
            z: vols,
            hovertemplate: 'Strike:%{x}<br>Exp.Date:%{y}<br>Vol: %{z:.0f}%<extra></extra>',
            type: 'surface',
            contours: {
              z: {
                show:true,
                label:'test',
                usecolormap: true,
                highlightcolor:"#42f462",
                project:{z: true}
              }
            },
        }
      ];

      setData(surface_data);

      var layout = {

        title: { text: props.type, font: { size: 12, color: '#FFA500', weight:600}, padding:'10px',
        x: 0.01,  // Center horizontally (0: left, 1: right)
        y: 0.05, // Move title inside (1 is top, 0 is bottom)
        xanchor: 'left'}, // Aligns title to the left }, // Set title here
        scene: {
            xaxis: { range: [adjusted_strikes[0], adjusted_strikes[adjusted_strikes.length - 1]] },  // Fixed x-axis range
            yaxis: { range: [expirations[0], expirations[expirations.length - 1]] },  // Fixed y-axis range
            zaxis: { range: [vols[0], vols[vols.length-1]] }   // Fixed z-axis range
          },
        color: "#fff",
        font_color: "#fff",
        paper_bgcolor:"rgba(0,0,0,0)",
        autosize:true,
        margin:{ l:0,r:0,b:20,t:20 },
                                    scene: {
                                      xaxis: {title:"Strike", color:'#505357',  gridcolor:'#8c8f90',   "gridwidth": 1 },
                                      yaxis: {title:"Expiration", color:'#505357', gridcolor:'#8c8f90',   "gridwidth": 1},
                                      zaxis: {title:"Volatility", color:'#505357', gridcolor:'#8c8f90',   "gridwidth": 1 }
                                    },
};

  setLayout(layout);

};

}, [props.vol_surface, props.type]);


  return(
      <div style={{/*display: "block", margin: "10px", borderRadius:'25px', width: "35vw", height: "35vh", border:'1px solid #424242'*/}}>
      <Plot layout={layout} data={data}  style={{ marginTop: "10px", width: "99%",
          height: "35vh", backgroundColor:'#192231',border:'1px solid #424242' }}
          onClick={handleClick} />
      </div>
    );
});

export default VolSurface;
