/*  This file is part of ql_rest, a free-software/open-source library
    for utilization of QuantLib over REST */

import BusinessDatePicker from './components/BusinessDatePicker'
import LabeledNumericInput from './components/LabeledNumericInput'
import Accordion from 'react-bootstrap/Accordion';
import StockPanel from './components/StockPanel'
import VolPanel from './components/VolPanel'
import SmilePanel from './components/SmilePanel'
import SurfacePanel from './components/SurfacePanel'
import PositionsPanel from './components/PositionsPanel'
import ExposurePanel from './components/ExposurePanel'
import GreeksPanel from './components/GreeksPanel'
import SingleStrikePricer from './components/SingleStrikePricer'
import Dropdown from './components/Dropdown'
import Ticket from './components/Ticket'
import PricerHelper from './helpers/PricerHelper'
import QuantLibHelper from './helpers/QuantLibHelper'
import SessionHelper from './helpers/SessionHelper'

import 'react-datepicker/dist/react-datepicker.css';

import { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap/';
import { compareAsc, format, parseISO } from 'date-fns'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import uuid from 'react-uuid'

import { GoogleLogin, GoogleOAuthenProvider, useGoogleLogin} from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CALCULATOR_COUNT } from './helpers/Constants'

import { RT_PRICE_URL, RISK_FREE_RATE_URL } from './helpers/Constants'

import { jwtDecode } from "jwt-decode";

import './App.css';

function App() {

  const volPanelRef = useRef();
  const smileRef = useRef();
  const greeksPanelRef = useRef();
  const userSessionVolRef = useRef();
  const calculatorReplyCounter = useRef();
  const calculatorReplyState = useRef();

  const [valuationDate, setValuationDate] = useState(new Date());
  const [defaultTicker, setDefaultTicker] = useState(undefined);
  const [exerciseTypes, setExerciseTypes] = useState();
  const [exerciseType, setExerciseType] = useState();
  const [pricerSetup, setPricerSetup] = useState();
  const [pricingModels, setPricingModels] = useState();
  const [pricingModel, setPricingModel] = useState();
  const [pricingToken, setPricingToken] = useState();
  const [straddlePricingToken, setStraddlePricingToken] = useState();
  const [pricingDisabled, setPricingDisabled] = useState();
  const [greeksDisabled, setGreeksDisabled] = useState();
  const [error, setError] = useState();
  const [portalSymbols, setPortalSymbols] = useState();
  const [volUpdate, setVolUpdate] = useState();
  const [volChartData, setVolChartData] = useState();
  const [interpolVolData, setInterpolVolData] = useState();
  const [selectedStrikeWithGreeks, setSelectedStrikeWithGreeks] = useState();
  const [pricedStrikeWithGreeks, setPricedStrikeWithGreeks] = useState();
  const [riskFreeRate, setRiskFreeRate] = useState(0.25);
  const [latency, setLatency] = useState();
  const [realTimePrice, setRealTimePrice] = useState();
  const [userCredentials, setUserCredentials] = useState();
  const [positions, setPositions] = useState({});
  const [exposure, setExposure] = useState();
  const [marketData, setMarketData] = useState();
  const [logonResponse, setLogonResponse] = useState();
  const [tradeBooked, setTradeBooked] = useState();
  const [panel, setPanel] = useState();
  const [surfaceSelect, setSurfaceSelect] = useState()

  const [selectedInstrument, setSelectedInstrument] = useState();
  const [selectedExpDate, setSelectedExpDate] = useState();
  const [selectedStrike, setSelectedStrike] = useState();


  useEffect(() => {

    var request = {}
    PricerHelper.get_data('get_pricer_setup', request, (pricer_setup) => { setPricerSetup(pricer_setup) });

    fetch(RISK_FREE_RATE_URL + '/key_rates/', {method:'POST',headers: { 'Content-Type': 'application/json' },body:'{}'})
      .then(res => res.json())
      .then(risk_free_rates => {

          if ( 'key_rates' in risk_free_rates )

            var key_rate_dates = Object.keys(risk_free_rates['key_rates']);
            var key_rate_date = key_rate_dates[0]

            if ( risk_free_rates['key_rates'][key_rate_date]['1Y'] != undefined )
            {
              var key_rate = risk_free_rates['key_rates'][key_rate_date]['1Y']
            setRiskFreeRate(key_rate)
            }
      } );

      userSessionVolRef.current = {}

  }, []);

  useEffect(() => {
    if (pricerSetup === undefined ) return;
    var exerciseTypes = pricerSetup['Exercises']
    setExerciseTypes(exerciseTypes)
  }, [pricerSetup]);

  /*
  useEffect(() => {

    if ( marketData == undefined || updateUnderlyingPrice == || activeStockTicker )
      return;

      var marketData = {}

      stockPrices.forEach((item, i) => { marketData[item['Symbol']] = item; });

      if (updateUnderlyingPrice !== undefined )
      {
        marketData[updateUnderlyingPrice['Symbol']]['Price'] = parseFloat(updateUnderlyingPrice['Price']);
      }

      setMarketData(marketData);

  }, [ marketData, activeStockTicker ]);
  */

  useEffect(() => {

    if (exerciseTypes === undefined )
      return;

    setExerciseType(exerciseTypes[1]);

  }, [exerciseTypes, pricerSetup]);

  useEffect(() => {

    if (exerciseType === undefined || pricerSetup === undefined )
      return;

    var models = pricerSetup['Models'][exerciseType.value];

    setPricingModels(models);

  }, [exerciseType, pricerSetup]);

  useEffect(() => {

   if (
     volChartData == undefined ||
     valuationDate == undefined ||
     selectedInstrument == undefined ||
     selectedExpDate == undefined ||
     riskFreeRate == undefined ||
     exerciseType == undefined ||
     pricingModel == undefined ||
     marketData == undefined )
   {
     return
   }

   if ( !(selectedInstrument['ticker'] in volChartData['vols']) )
   {
     return;
   }
   console.log(riskFreeRate)

   //var price_request = {}

  //var vols_and_payoffs = {}
   //vols_and_payoffs['call'] = []
   //vols_and_payoffs['put'] = []

   var request_id = uuid();

   /*var symbol = volChartData.active_stock_ticker['Symbol']
   var expiration_date = volChartData['expiration_date']
   var symbol_vols = volChartData['vols'][symbol][expiration_date]*/

   var exp_vols = PricerHelper.retrieve_exp_vols(volChartData,selectedExpDate)

   if (exp_vols['symbol_vols'] === undefined)
   {
     alert('No Vols')

     setSelectedInstrument(undefined)
     return
   }

  // var expiration_date = exp_vols['expiration_date']

   var volData = PricerHelper.get_exercise_date_vols(volChartData['merged_strikes'], exp_vols['symbol_vols'])

   var vols_and_payoffs = [{'call':[],'put':[]},{'call':[],'put':[]},{'call':[],'put':[]},{'call':[],'put':[]}]

   var ticker = selectedInstrument['ticker']
   var instrument_market_data = marketData[ticker]

   var instrument_count = 0
   for ( var vol in volData )
   {
     var calculator_id = instrument_count%CALCULATOR_COUNT
     //var expiry_date_iso = PricerHelper.expiry_date_iso(expiration_date)

     var option_details = {}
     option_details['request_id'] = request_id
     option_details['Underlying'] = instrument_market_data.Price
     option_details['Strike'] = volData[vol].Strike
     option_details['div_rate'] = parseFloat(instrument_market_data.dividend)
     option_details['RiskFreeRate'] = riskFreeRate/100.0
     option_details['exerciseType'] = exerciseType.value
     option_details['valuationDate'] = valuationDate
     option_details['expiry_date_iso'] = selectedExpDate
     option_details['expiry_date_vol'] = vol
     option_details['model'] = pricingModel.value

     if ( volData[vol].call_vol != undefined )
     {
       option_details['OptionType'] = 'Call'
       option_details['vol'] = volData[vol].call_vol

       var call = QuantLibHelper.get_option_termstructure(exerciseType.value, option_details)
       vols_and_payoffs[calculator_id]['call'].push(call)
     }

     if ( volData[vol].put_vol  != undefined )
     {
       option_details['OptionType'] = 'Put'
       option_details['vol'] = volData[vol].put_vol

       var put = QuantLibHelper.get_option_termstructure(exerciseType.value, option_details)
       vols_and_payoffs[calculator_id]['put'].push(put)
     }

     instrument_count = instrument_count + 1
   }
   setPricingDisabled(true);
   setSelectedStrikeWithGreeks(undefined);
   //setSelectedStrike(undefined);
   setVolUpdate(undefined);

   calculatorReplyCounter.current = 0
   calculatorReplyState.current = {}

   vols_and_payoffs.forEach((item, i) => {
        console.log(item)
        repriceInstrumentGroup(valuationDate, i, item);
   });


 }, [ volChartData, valuationDate, selectedInstrument, riskFreeRate, marketData, exerciseType, pricingModel, selectedExpDate ]);


  const repriceInstrumentGroup = (valuationDate, groupId, instrumentGroup) =>
  {
   var price_request = {}
   price_request["request_id"] = uuid();
   price_request["portal"] = "OPTIONS_PORTAL"
   price_request["operation"] = "PRICE"
   price_request["business_date"] = format(valuationDate, "yyyy-MM-dd")
   price_request['vols_and_payoffs'] = instrumentGroup

   PricerHelper.submit_request(price_request, (pricingToken) => {
        //setTimeout(() => {
          console.log("Submitted out : " + JSON.stringify(pricingToken))
          //setPricingToken(pricingToken);
          calculatorReplyState.current[pricingToken.request_id] = pricingToken;
          const length = Object.keys(calculatorReplyState.current).length;
          console.log("Number of elements in Dict " + length)
          if ( length == 4 )
            setStraddlePricingToken(calculatorReplyState.current)
        //}, 200 * (groupId+1) );
   });
 }

  useEffect(() => {

   if (straddlePricingToken == undefined || calculatorReplyState.current == undefined )
      return;

      Object.keys(straddlePricingToken).map((pricer_key, index) => {
        console.log("Lets check : " +pricer_key + ":" + JSON.stringify(straddlePricingToken[pricer_key]))
        //setPricingToken(straddlePricingToken[pricer_key])
        PricerHelper.check_request(straddlePricingToken[pricer_key], (pricingResults) =>
        {
            console.log("->>>>>Result State: " + pricingResults.state + ":" + calculatorReplyCounter.current );
            if (pricingResults.state == 2)
            {
              var greeks = PricerHelper.get_greeks(pricingResults);
              greeksPanelRef.current.update_greeks(greeks);

              delete calculatorReplyState.current[pricingResults.request_id]

              calculatorReplyCounter.current = calculatorReplyCounter.current + 1

              if (calculatorReplyCounter.current == CALCULATOR_COUNT)
              {
                setPricingDisabled(false);
                setGreeksDisabled(false);
              }

            } else {
              //calculatorReplyState.current[pricingResults.request_id] = pricingResults
              var pending_requests = {...calculatorReplyState.current}
              setStraddlePricingToken(pending_requests)
            }
        });
      });

      //setStraddlePricingToken(pending_requests)

      /*const length = Object.keys(pending_requests).length;

      if ( length == 0 )
      {
        //setError(pricingResults.error);
        setPricingDisabled(false);
        setGreeksDisabled(false);
      } else {
        setStraddlePricingToken(pending_requests)*/
    //  }

  }, [straddlePricingToken]);

  useEffect(() => {

   if (pricingToken == undefined)
      return;

   console.log("Checking Pricing Request : " + JSON.stringify(pricingToken))

   PricerHelper.check_request(pricingToken, (pricingResults) =>
   {
     if ( pricingResults.state == 1 && pricingResults['operation'] == 'VOL_CALC' )
     {
       var pricer_re_request = {};
       pricer_re_request["request_id"] = pricingResults.request_id;
       pricer_re_request["url"] = pricingResults.url;
       console.log("Re-Checking Pricing Request : " + JSON.stringify(pricer_re_request))
       setPricingToken(pricer_re_request);
       return;

     } else {

       if ( pricingResults['operation'] == 'PRICE')
       {
         calculatorReplyCounter.current = calculatorReplyCounter.current + 1

         if (calculatorReplyCounter.current == CALCULATOR_COUNT )
         {
           if (selectedStrike !== undefined )
           {
             greeksPanelRef.current.flash_stike(selectedStrike);
           }
         }

         var greeks = PricerHelper.get_greeks(pricingResults);
         greeksPanelRef.current.update_greeks(greeks);

       } else if (pricingResults['operation'] == 'VOL_CALC') {


         Object.keys(pricingResults['vols']).forEach((symbol, i) => {
           var interpolated_vols = pricingResults['vols'][symbol]
           userSessionVolRef.current[symbol]['interpolated_vols']=interpolated_vols
         });

        const updated_interpol_vol_data = JSON.parse(JSON.stringify(userSessionVolRef.current));

         setInterpolVolData(updated_interpol_vol_data)

       } else {
         setError(pricingResults.error);
         setPricingDisabled(false);
         setGreeksDisabled(false);
         console.log(pricingResults);
       }
       setPricingToken(undefined);
     }
   }, 250);


  }, [pricingToken, selectedStrike]);

  useEffect(() => {

      if ( interpolVolData != undefined && selectedInstrument != undefined
          && interpolVolData[selectedInstrument['ticker']] != undefined )
      {
        var active_instrument_vol_data = interpolVolData[selectedInstrument['ticker']]

        if ( volUpdate != undefined )
        {
          var strike = volUpdate.data.Strike;

          var intepolated_vol_data = userSessionVolRef.current[selectedInstrument['ticker']]['interpolated_vols'][exerciseDate.value]

          if ( intepolated_vol_data['diviser'] != undefined )
            strike = strike * parseFloat(intepolated_vol_data['diviser'])

          const updated_interpol_vol_data = JSON.parse(JSON.stringify(intepolated_vol_data))

          updated_interpol_vol_data['calls'][strike] =  parseFloat(volUpdate.data.call_vol)
          updated_interpol_vol_data['puts'][strike] = parseFloat(volUpdate.data.put_vol)
          updated_interpol_vol_data['merged_strikes'] = userSessionVolRef.current[activeStockTicker.Symbol]['merged_strikes']

          userSessionVolRef.current[activeStockTicker.Symbol]['interpolated_vols'][exerciseDate.value] = updated_interpol_vol_data
          const interpol_vol_data = JSON.parse(JSON.stringify(userSessionVolRef.current));

          setInterpolVolData(interpol_vol_data);
          setVolChartData(updated_interpol_vol_data);
          setActiveInstrumentVols(updated_interpol_vol_data);
          setVolUpdate(undefined);
        }
        //else {

          /*setInterpolVolData(interpol_vol_data);
          setVolChartData(updated_interpol_vol_data);
          setActiveInstrumentVols(updated_interpol_vol_data);
          setVolUpdate(undefined);*

          /*
          const vol_chart_data = JSON.parse(JSON.stringify(interpolVolData[activeStockTicker.Symbol]['interpolated_vols'][exerciseDate.value]));

          vol_chart_data['merged_strikes'] = userSessionVolRef.current[activeStockTicker.Symbol]['merged_strikes']
          setVolChartData(vol_chart_data);
          setActiveInstrumentVols(vol_chart_data);*/

      //  }
      }

  }, [interpolVolData, selectedInstrument, volUpdate/*, exerciseDate*/]);

  /*useEffect(() => {

    if ( interpolType != undefined && activeStockTicker != undefined && userSessionVolRef.current[activeStockTicker.Symbol] != undefined )
    {
      var ticker_vols = userSessionVolRef.current[activeStockTicker.Symbol]['original_vols']
      //var ticker_vols_expiry = ticker_vols[exerciseDate.value]
      ticker_vols_expiry['merged_strikes'] = userSessionVolRef.current[activeStockTicker.Symbol]['merged_strikes']
      //setVolChartData(ticker_vols_expiry);

      const vol_greeks_data = PricerHelper.get_exercise_date_vols(userSessionVolRef.current[activeStockTicker.Symbol]['merged_strikes'], ticker_vols_expiry)
      setVolGreekData(vol_greeks_data);

      var price_request = {}

      var request_id = uuid();
      price_request["request_id"] = request_id
      price_request["portal"] = "OPTIONS_PORTAL"
      price_request["operation"] = "VOL_CALC"

      var interpol_request =
          QuantLibHelper.get_interpolation_request(
          request_id,
          ticker_vols,
          ticker_vols_expiry['merged_strikes'],
          interpolType.value)
      price_request["request"] = {}
      price_request["request"][activeStockTicker.Symbol] = interpol_request

      PricerHelper.submit_request(price_request, (pricingToken) => { setPricingToken(pricingToken); });
    }

  }, [interpolType, activeStockTicker]);*/

  useEffect(() => {

    if ( portalSymbols == undefined )
      return;

    var ticker_request = {};
    ticker_request['tickers'] = [];
    //portalSymbols.forEach(symbol => ticker_request['tickers'].push(symbol['Symbol']));
    //ticker_request['tickers'].push('risk_free_rates');

              var realtime_market_data_request = {}
              realtime_market_data_request["portal"] = "OPTION_PORTAL";
              realtime_market_data_request['start_date'] = 20000101
              realtime_market_data_request['end_date'] = 20000101
              realtime_market_data_request['tickers'] = []

              portalSymbols.forEach((instrument, i) => {
                realtime_market_data_request['tickers'].push(instrument['ticker'])
              });

              //setStockPrices(updated_market_data);

              //updated_market_data.forEach(symbol => realtime_market_data_request['tickers'].push(symbol['Symbol']));

              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(realtime_market_data_request)
              };

              fetch(RT_PRICE_URL + '/rtdata/', requestOptions)
                .then(res => res.json())
                .then(real_time_price => {

                    var pricing_data = real_time_price['data']
                    var real_time_market_data = {}
                    Object.keys(pricing_data).forEach(symbol => {

                      var market_data = pricing_data[symbol]['market_data'][0]
                      real_time_market_data[symbol] = market_data;
                    });

                    setRealTimePrice(real_time_market_data)

                } );

            //}

            //var risk_free_rate = tickers_with_prices['risk_free_rates']['1Y'];

            //setRiskFreeRate(risk_free_rate);
            //setStockPrices(updated_market_data);

  }, [portalSymbols]);

  /*
  useEffect(() => {

    if ( selectedInstrument != undefined )
    {
      setVolChartData(undefined)
    }

  }, [selectedInstrument]);
  */

  useEffect(() => {

    if ( userCredentials != undefined )
    {
      SessionHelper.submit_login(userCredentials, (logon_response) => { setLogonResponse(logon_response['data']) });
      setPositions(undefined);
      setExposure(undefined);
    }

  }, [userCredentials]);

  useEffect(() => {

    if ( realTimePrice != undefined )
    {
      setDefaultTicker('AAPL')
    }

  }, [realTimePrice]);

  useEffect(() => {
    if ( logonResponse != undefined ){
        var position_request = {}
        position_request = {}
        position_request['email'] = logonResponse['email']
        position_request['jti'] = logonResponse['jti']

        SessionHelper.get_positions(position_request, (positions_in) =>
          {
            if ( positions_in !== undefined && positions_in.positions !== undefined )
            {
              //setInterpolVolData(undefined);

              var positions_out = {}
              positions_in.positions.forEach((position, i) => {
                positions_out[position['instrument_details']['symbol']] = position;
              });

              setPositions(positions_out);


              var price_request = {}
              var request_id = uuid();
              price_request["request_id"] = request_id
              price_request["portal"] = "OPTIONS_PORTAL"
              price_request["operation"] = "VOL_CALC"
              price_request["request"] = {}

              positions_in.vols.forEach((vols_in, i) => {

                if ( userSessionVolRef.current[vols_in['symbol']] === undefined )
                {

                  var merged_strikes = PricerHelper.get_merged_strikes(vols_in['vol_data']);


                  var interpol_request = QuantLibHelper.get_interpolation_request(uuid(), vols_in['vol_data'], merged_strikes, 'LINEAR')
                  price_request["request"][vols_in['symbol']] = interpol_request

                  var exercises_dates = []
                  Object.keys( vols_in['vol_data'] ).forEach((item, i) => {
                    var exercise = {};
                    exercise['value'] = item;
                    exercise['label'] = item;
                    exercises_dates.push(exercise);
                  });

                  var user_session_vols = {}
                  user_session_vols['exercise_dates'] = exercises_dates
                  user_session_vols['original_vols'] = vols_in['vol_data']
                  user_session_vols['interpolated_vols'] = vols_in['vol_data']

                  userSessionVolRef.current[vols_in['symbol']] = user_session_vols
                }

              });

              PricerHelper.submit_request(price_request, (pricingToken) => { setPricingToken(pricingToken); });
            }
          });
    };
  }, [logonResponse, tradeBooked]);

  const VolPanelSetup = {
    pricingData:{},
    curve_price_callback : ( priced_curve ) => {},
    pricing_start : () => { setPricingDisabled(true) },
  };
  const responseMessage = (response) => {

      const USER_CREDENTIAL = jwtDecode(response.credential);
      setUserCredentials(USER_CREDENTIAL);

    };
  const errorMessage = (error) => { console.log(error); };
  const logged_in_div = <div style={{textAlign:'left'}}>
        <Row>
          <Col>
            {userCredentials == undefined ? null : userCredentials.name}
          </Col>
        </Row>
        <Row>
          <Col style={{fontSize:'10px'}}>
            {userCredentials == undefined ? null : userCredentials.email}
          </Col>
        </Row> </div>;

  return (
    <div  style={{textAlign: 'left', color:'#ffeed9'}}>
      <nav>
            <h3 className="nav--logo_text">
              <div style={{paddingTop:'10px', paddingLeft:'5px', fontSize:'20px',justifyContent: 'left'}}>
                Vanilla Options
                <div style={{display: 'flex', justifyContent: 'left', fontSize:'14px', fontWeight:'500', paddingTop:'0px', color:'#ffeed9'}}>
                  <a className="App-link"  href="mailto:support@alpharesearch.online">Support</a>
                </div>
              </div>
            </h3>

            <Row style={{opacity:'80%'}}>
              <Col className="nav--logo_text">
                <div style={{fontSize:'14px',  textAlign: 'right', textWrap: 'nowrap'}}>
                {
                  userCredentials == undefined ? <div style={{width:100, paddingTop:'5px'}}>Sign-in with</div> : logged_in_div
                }
                </div>
              </Col>
              <Col>
              <GoogleOAuthProvider clientId="66097256498-ifgfagl8lb2n2sv95n47d9r19qatjipc.apps.googleusercontent.com">
                <div style={{margin:'0px'}}>
                  <GoogleLogin onSuccess={responseMessage} onError={errorMessage} theme='filled_black' size='medium' type='icon'/>
                </div>
              </GoogleOAuthProvider>
              </Col>
            </Row></nav>
      <div className="ag-theme-balham-dark">
        <Container fluid  style={pricingDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
          <Accordion defaultActiveKey={['0','1','2']} alwaysOpen>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Market Data</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col style={{textAlign:'Right', marginLeft:'auto', marginRight:0 }}>
                    <Row>
                      <Col>
                        <Row>
                          <Col style={{marginTop:'6px', textAlign:'Left'}}>
                            <Row>
                            <Col xs='7'>
                            <div style={{marginBottom:'4px', fontSize:'15px'}} className="ag-theme-balham-dark">
                            <LabeledNumericInput label="Risk Free Rate:" value={riskFreeRate}
                                elementName="riskFreeRate" width="75px"
                                onChanged={(elementName, new_value)=>{setRiskFreeRate(new_value);}}
                                onChange= {(elementName, new_value)=>{setGreeksDisabled(true);}} postfix='%'
                                step={0.025}/>
                            </div>
                            </Col>
                            </Row>
                            <Row>
                            <StockPanel symbols={portalSymbols} realTimePrice={realTimePrice} defaultTicker={defaultTicker}
                              gridReadyCallback = { () => {
                                  var intial_ticker_request = {}
                                PricerHelper.get_data('get_instruments', intial_ticker_request, (symbols) => {
                                  setPortalSymbols(symbols);
                                });
                              }
                            }

                            onMarketDataChange = { (marketData) => { setMarketData(marketData) } }
                            onInstrumentChange = { (instrument) => {
                                setSelectedStrike(undefined)
                                setSelectedInstrument(instrument)
                              }}

                            />
                        </Row>
                      </Col>
                        </Row>
                      </Col>
                      <Col>
                      <Row>
                        <Col style={{marginTop:'35px', textAlign:'Right'}}>
                          <VolPanel

                              setup={VolPanelSetup}
                              selectedInstrument={selectedInstrument}
                              marketData={marketData}
                              selectedExpDate={selectedExpDate}
                              selectedStrike={selectedStrike}
                              surfaceSelect = {surfaceSelect}

                              ref={volPanelRef}

                              strikeSelectedCallback={ (strike) => {
                                setSelectedStrike(strike);
                              }}

                              volDataCallback={ (volChartData) => {
                                  setVolChartData(volChartData)
                                  var ticker = volChartData['selected_instrument']['ticker']
                                  userSessionVolRef.current[ticker] = volChartData['vols']['ticker']
                              }}

                              selectedExpDateCallback={ (expDate ) => {
                                    setSelectedExpDate( expDate );
                              }}

                              />
                  </Col>
                </Row>
                </Col>
                    </Row>
                    <Row  style={{textAlign: 'center', color:'#ffeed9'}}>
          <div className="main--sub--title">
            (Double click to change the Price or Call/Put Volatility)
          </div>
                    </Row>
                    </Col>
                    <Col>
                    <Row>
                    <Col>
                    <div style={pricingDisabled ? {pointerEvents: "none", opacity: "0.4", height:'34vh'} : {height:'34vh'}}>
                      <SmilePanel selectedInstrument={selectedInstrument}
                                  volData={volChartData}
                                  selectedExpDate={selectedExpDate}
                                  strikeSelectedCallback={ (strike) => { setSelectedStrike(strike); } } ref={smileRef} />
                    </div>
                    </Col>
                    </Row>
                    </Col>
                    </Row>
                    <Row>
                  <Col>
                    {error}
                  </Col>
                  </Row>
                </Accordion.Body>
            </Accordion.Item>

              <Accordion.Item eventKey='1'>
              <Accordion.Header>Volatility Surface</Accordion.Header>
                <Accordion.Body>
                <Row>
                <Col>
                  <SurfacePanel interpolated_vols={volChartData} selectedInstrument={selectedInstrument}
                  option_side_setup = {(option_side) =>
                  {
                    setSelectedExpDate(option_side['expiration_date'])
                    setSelectedStrike(option_side['Strike'])
                  } }/>
                </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey='2'>
            <Accordion.Header>Pricing and Analytics</Accordion.Header>
              <Accordion.Body>
                <Row style={{textAlign: 'left', marginTop:'10px'}}>
      <Col>
      <div className='ag-theme-balham-dark' style={{paddingTop:'15px', fontSize:'16px'}}>
          <Row>
          <Col xs='2'>
            <div style={{paddingLeft:'5px', paddingTop:'3px', color:'#FFA500', fontWeight:500}}>
              Exp: { selectedExpDate != undefined ? selectedExpDate : '' }
            </div>
          </Col>
            <Col xs='3' style={{paddingTop:'3px'}}>
              <BusinessDatePicker label='Valuation:' elementName='business_date' selected_date={valuationDate}
                onValueChange={ (elementName, date) =>
                  {
                    setValuationDate(date);} }/>
            </Col>
            <Col xs='3'>
                <Dropdown  label='Exercise:' elementName='ExerciseType'
                  options={exerciseTypes}
                  selected_value={exerciseType}
                  onValueChange={ ( element, selected_option ) => {
                  if ( selected_option !== exerciseType )
                  {
                    setPricingModel(undefined);
                    setExerciseType(selected_option);
                  }
                } }/>
                </Col>
              <Col xs='4'>
                  <Dropdown label='Model:' elementName='PricingModel' options={pricingModels} selected_value={pricingModel} onValueChange={ ( element, selected_option ) => { setPricingModel(selected_option); } }/>
              </Col>
              </Row>
            </div>
        <div style={greeksDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
        <Row>
          <Col>
            <GreeksPanel volData={volChartData}
                selectedStrike={selectedStrike}
                selectedInstrument={selectedInstrument}
                marketData={marketData}
                ref={greeksPanelRef}
                strikeSelectedCallback={ (strikeWithGreeks) => { setSelectedStrikeWithGreeks(strikeWithGreeks); } }/>
          </Col>
          </Row>
          <Row  style={{textAlign:'left'}}>
          <Col>
          </Col>
          </Row>
        </div>
        </Col>
      <Col>
        <Row>
          <Col>
            <SingleStrikePricer strikeWithGreeks = {selectedStrikeWithGreeks} exerciseType = {exerciseType} pricingModel = {pricingModel} strikeSelectedCallback={ (pricedStrikeWithGreeks) => { setPricedStrikeWithGreeks(pricedStrikeWithGreeks);} } />
          </Col>
        </Row>
        <Row style={{marginLeft:'5px', marginRight:'30px'}}>
          <Col style={{border:'1px solid #424242', backgroundColor:'#192231'}}>
          <div style={ ( userCredentials == undefined ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
              <Ticket selectedInstrument={selectedInstrument}
                      marketData={marketData}
                      positions={positions}
                      userCredentials={logonResponse}
                      pricedStrikeWithGreeks = {pricedStrikeWithGreeks}
                      exerciseType = {exerciseType} tradeBookCallback={(response)=>{ setTradeBooked(response); }}/>
          </div>
          </Col>
        </Row>
      </Col>
                </Row>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey='3'>
              <Accordion.Header>Positions</Accordion.Header>
                <Accordion.Body>
                <div style={{paddingTop:'10px'}}>
    <Row>
      <Col>
        <div style={ ( userCredentials == undefined ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
          <PositionsPanel positions={positions} marketData={marketData} userSessionVols={userSessionVolRef.current} valuationDate={valuationDate}
            europeanPricingModel={{value:'ADE'}} americanPricingModel={{value:'CRR'}} userCredentials={userCredentials}
            setExposure={ (exposure) => {
              setExposure(exposure);
            } }/>
        </div>
      </Col>
      <Col>
        <div style={ ( userCredentials == undefined ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
          <ExposurePanel exposure={exposure}/>
        </div>
      </Col>
    </Row>
                </div>
                </Accordion.Body>
              </Accordion.Item>
          </Accordion>
        </Container></div>
    </div>
  );
}

export default App;
