/*  This file is part of ql_rest, a free-software/open-source library
    for utilization of QuantLib over REST */

import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart }            from 'react-chartjs-2';
import { useEffect, useState } from 'react';

import PricerHelper from '../helpers/PricerHelper'


const { forwardRef, useRef, useImperativeHandle } = React;

const SmilePanel = React.forwardRef ((props, ref) => {

  const chartRef = useRef();

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [name, setName] = useState("...");


  const [options, setOptions] = useState({
    labels: [],
    datasets: [],
    maintainAspectRatio: false,
  });

  useEffect(() => {

          var strikes = [];
          var put_vols = [];
          var call_vols = [];
          var name = "N/A";
          var exerciseDate = "N/A";

          if ( props.volData != undefined && props.selectedInstrument != undefined &&
            props.selectedExpDate != undefined && props.selectedInstrument['ticker'] in props.volData['vols'] )
          {
            var selected_instrument = props.selectedInstrument
            var ticker = selected_instrument['ticker']
            var name = selected_instrument['name']
            exerciseDate = props.selectedExpDate
            setName(name)
            var volData = props.volData['vols'][ticker][props.selectedExpDate]

            if ( volData == undefined )
            {
              alert('No Vols')
              return
            }
            var mergedStrikes = props.volData['merged_strikes']

            for (const vol of PricerHelper.get_exercise_date_vols(mergedStrikes, volData, 1))
            {
              put_vols.push(vol.put_vol)
              call_vols.push(vol.call_vol)
              strikes.push(vol.Strike)
            }

            //exerciseDate = exerciseDate

          }

          const char_data = {
            labels: strikes,
            datasets: [
              {
                data : call_vols,
                fill: true,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                label : 'Calls',
                hidden: false,
                borderWidth: 1.5,
                lineTension: 0.4
              },
              {
                data : put_vols,
                fill: true,
                backgroundColor: 'rgba(255, 99, 132,0.2)',
                borderColor: 'rgb(255, 99, 132)',
                label : 'Puts',
                hidden: false,
                borderWidth: 1.5,
                lineTension: 0.4
              }
          ]
          };

          const options = {
              responsive: true,
                onClick:(event, elements, chart) => {
                    if (elements.length > 0) {
                        const clickedIndex = elements[0].index;
                        const chartData = chart.config.data; // Get data from chart instance
                        const clickedLabel = chartData.labels[clickedIndex]; // Get X label
                        const clickedValue = chartData.datasets[0].data[clickedIndex]; // Get Y value
                        console.log("Clicked Label:", clickedLabel, "Value:", clickedValue);
                        props.strikeSelectedCallback(clickedLabel)
                      }
                },
            elements: {
                   point:{
                       radius: 1.125
                   }
               },
                 plugins: {
                   title: {
                     display: true,
                     align: 'center',
                     text: exerciseDate,
                     position: 'right',
                     color:'#FFA500',
                   },
                 }};

          setOptions(options);
          setData(char_data);

  }, [props.volData, props.selectedInstrument, props.selectedExpDate]);


  return (
    <div style={{height:'34vh', padding:'5px'}}>
      <div className="ag-theme-balham-dark" style={{textAlign:'right',
        color:'#ffeed9', fontWeight:700, paddingRight:'20px', paddingBottom:'10px', fontSize:'20px'}}>
          {name}
      </div>
      <div>
        <Line data={data}
          style={{border:'1px solid #424242',
          backgroundColor:'#192231', position: 'relative', height:'34vh'}}
          options={options}
          ref={chartRef}/>
      </div>
    </div>
  )
});

export default SmilePanel;
