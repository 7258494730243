/*  This file is part of ql_rest, a free-software/open-source library
    for utilization of QuantLib over REST */

import { useEffect, useState } from 'react';
import React from 'react';
import QuantLibHelper from '../helpers/QuantLibHelper'
import PricerHelper from '../helpers/PricerHelper'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { Container, Row, Col } from 'react-bootstrap/';
import { compareAsc, format, parseISO } from 'date-fns'

import uuid from 'react-uuid'

const { forwardRef, useRef, useImperativeHandle } = React;

const PositionsPanel = React.forwardRef ((props, ref) => {

  const positionsGridRef = React.useRef();

  const [positions, setPositions] = useState();
  const [repricedPositions, setRepricedPositions] = useState();
  const [pricingToken, setPricingToken] = useState();


  const numberFormatter = (params) => {
      if (!isNaN(params.value)) return parseFloat(params.value).toFixed(2);
  };

  function compareCall(params) {
    /*if (params.data.Strike <= props.marketPrice.Price ){
      return {'textAlign': 'right', padding:'0px', color: 'rgba(75,192,192,1)'};
    } else {
      return {'textAlign': 'right', padding:'0px', color: 'rgba(75,192,192,0.7)'};
    }*/
  }

  function comparePut(params) {
    /*if (params.data.Strike >= props.marketPrice.Price ){
      return {'textAlign': 'right', padding:'0px', color: 'rgba(255, 99, 132,1)'};
    } else {
      return {'textAlign': 'right', padding:'0px', color: 'rgba(255, 99, 132,0.7)'};
    }*/
  }

  function compareCallGreeks(params) {
    /*if (params.data.Strike <= props.marketPrice.Price ){
      return {'textAlign': 'right', padding:'0px', opacity: '1'};
    } else {
      return {'textAlign': 'right', padding:'0px', opacity: '0.7'};
    }*/
  }

  function comparePutGreeks(params) {
  /*  if (params.data.Strike >= props.marketPrice.Price ){
      return {'textAlign': 'right', padding:'0px', opacity: '1'};
    } else {
      return {'textAlign': 'right', padding:'0px', opacity: '0.7'};
    }*/
  }


  function riskCellRenderer ( props ) {
    if (!isNaN(props.value)) return parseFloat(props.value).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  function positionCellRenderer ( props ) {
    if (!isNaN(props.value)) return parseInt(props.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };


  const positionsGridColumnDefs = [
    { field: 'symbol', headerName: 'Symbol',  sortable: true, cellStyle: {'textAlign': 'left'}, hide: true , resizable: true},
    { field: 'name', headerName: 'Name',  sortable: true, flex: 1, cellStyle: {'textAlign': 'left'},  pinned: 'left', width: '200px', flex: 3 , resizable: true},
    { field: 'position_npv', headerName:'Price' ,  sortable: true, cellStyle: {'textAlign': 'right'}, cellRenderer:riskCellRenderer, flex: 0, width: '75px', resizable: true },
    { field: 'position', headerName:'Position' ,  sortable: true,  cellStyle: {'textAlign': 'right'}, cellRenderer:positionCellRenderer, flex: 0, width: '75px', resizable: true  },
    { field: 'pnl', headerName:'P&L' ,  sortable: true,  cellStyle: {'textAlign': 'right'}, cellRenderer:riskCellRenderer, flex: 0, width: '75px', resizable: true  },
    { field: 'position_vol', headerName:'Vol' ,  sortable: true,  cellStyle: {'textAlign': 'right'}, cellRenderer:positionCellRenderer, flex: 0, width: '75px' , resizable: true },
    { field: 'position_delta', headerName:'Delta' ,  sortable: true,  cellStyle: {'textAlign': 'right'}, cellRenderer:riskCellRenderer, flex: 0, width: '75px' , resizable: true  },
    { field: 'position_gamma', headerName:'Gamma' ,  sortable: true,  cellStyle: {'textAlign': 'right'}, cellRenderer:riskCellRenderer, flex: 0, width: '75px' , resizable: true  },
    { field: 'position_vega',  headerName:'Vega' ,  sortable: true,  cellStyle: {'textAlign': 'right'}, cellRenderer:riskCellRenderer, flex: 0, width: '75px' , resizable: true  },
    { field: 'position_theta', headerName:'Theta' ,  sortable: true,  cellStyle: {'textAlign': 'right'}, cellRenderer:riskCellRenderer, flex: 0, width: '75px', resizable: true   },
    { field: 'position_rho', headerName:'Rho' ,  sortable: true, cellStyle: {'textAlign': 'right'}, cellRenderer:riskCellRenderer, flex: 0, width: '75px', resizable: true   },
    { field: 'expiration_date', headerName:'Exp.Date.' ,  sortable: true, cellStyle: {'textAlign': 'right'}, flex: 0, width: '100px', resizable: true  },
    { field: 'vwap', headerName:'VWAP' ,  sortable: true, cellStyle: {'textAlign': 'right'}, cellRenderer:riskCellRenderer, flex: 0, width: '75px', resizable: true },
  ];



  const positionsGridOptions = {

    rowSelection: 'single',

    getRowHeight: (params) => 25,

    onRowSelected(event) {

      /* if (!event.node.selected || event.data.RiskFreeRate === undefined)
        return;

      props.strikeSelectedCallback(event);*/
    },

  }

  useEffect(() => {

    if ( props.selectedStrike != null )
    {
    }

  }, [props.selectedStrike]);

  useEffect(() => {

    if ( positions != undefined && repricedPositions != undefined )
    {
      console.log(positions)

      var exposure = {}

      positions.forEach((position, i) => {

        var instrument_type = position['instrument_details']['type']

        console.log(instrument_type);

        var exposure_symbol = ''
        var pnl = 0
        var delta = 0;
        var vega = 0;
        var gamma = 0;
        var theta = 0;
        var rho = 0;
        var quantity = 0;

        if ( instrument_type == 'stock' )
        {
          exposure_symbol = position['instrument_details']['symbol']
          quantity = position['position']
          pnl = position['pnl']
        } else if ( instrument_type == 'option' )
        {
          exposure_symbol = position['instrument_details']['underlying_symbol']
          var position_amt = (position['position_details']['buy_amt'] - position['position_details']['sell_amt'])
          var position_vwap = ( (position['position_details']['buy_avg_price'] * position['position_details']['buy_amt']) -
            (position['position_details']['sell_avg_price'] * position['position_details']['sell_amt']) )/position_amt

          var option_symbol = position['instrument_details']['symbol']
          if ( option_symbol in repricedPositions )
          {
            var option_npv = repricedPositions[option_symbol]['position_npv']
            quantity = position['quantity']
            pnl = (option_npv-position_vwap) * position['quantity']
            delta = repricedPositions[option_symbol]['position_delta']
            gamma = repricedPositions[option_symbol]['position_gamma']
            vega = repricedPositions[option_symbol]['position_vega']
            theta = repricedPositions[option_symbol]['position_theta']
            rho = repricedPositions[option_symbol]['position_rho']

          }
        }

        console.log(exposure_symbol + " : " + pnl);

        if ( exposure[exposure_symbol] == undefined )
        {
          exposure[exposure_symbol] = {}
          exposure[exposure_symbol]['pnl'] = pnl
          exposure[exposure_symbol]['delta'] = delta
          exposure[exposure_symbol]['gamma'] = gamma
          exposure[exposure_symbol]['vega'] = vega
          exposure[exposure_symbol]['theta'] = theta
          exposure[exposure_symbol]['rho'] = rho
          exposure[exposure_symbol]['quantity'] = quantity
          exposure[exposure_symbol]['symbol'] = exposure_symbol

          if ( exposure_symbol in props.marketData)
          {
            var exposure_symbol_market_data = props.marketData[exposure_symbol]
            exposure[exposure_symbol]['price'] = exposure_symbol_market_data['Price']
            exposure[exposure_symbol]['name'] = exposure_symbol_market_data['name']
          }

        } else {
          exposure[exposure_symbol]['pnl'] += pnl;
          exposure[exposure_symbol]['delta'] += delta
          exposure[exposure_symbol]['gamma'] += gamma
          exposure[exposure_symbol]['vega'] += vega
          exposure[exposure_symbol]['theta'] += theta
          exposure[exposure_symbol]['rho'] += rho
          exposure[exposure_symbol]['quantity'] += quantity
        }

      });

      //console.log(Object.values(exposure));

      props.setExposure(Object.values(exposure))

    }

  }, [positions, repricedPositions, props.marketData]);


  useEffect(() => {

    if ( pricingToken !== undefined )
    {

      PricerHelper.check_request(pricingToken, (pricingResults) =>
      {
        if (pricingResults.state == 1 )
        {
          var pricer_re_request = {};
          pricer_re_request["request_id"] = pricingResults.request_id;
          pricer_re_request["url"] = pricingToken.url;
          setPricingToken(pricer_re_request);
          return;
        } else {
          if ( pricingResults['operation'] == 'PRICE' && pricingResults.position !== undefined && pricingResults.position != "")
          {
            var priced_positions = {}
            pricingResults.position.forEach((position, i) => {
              priced_positions[position['symbol']] = position
            });

            setRepricedPositions(priced_positions);

            positionsGridRef.current.api.forEachNode(node =>
            {
              if (node.data == null )
                return;

              var symbol = node.data['instrument_details']['symbol'];

              var priced_position = priced_positions[symbol];

              if ( priced_position != undefined )
              {
                var quantity = node.data.quantity

                priced_position['position_delta'] = parseFloat(priced_position['position_delta'])*quantity
                priced_position['position_gamma'] = parseFloat(priced_position['position_gamma'])*quantity
                priced_position['position_vega'] = parseFloat(priced_position['position_vega'])*quantity
                priced_position['position_theta'] = parseFloat(priced_position['position_theta'])*quantity
                priced_position['position_rho'] = parseFloat(priced_position['position_rho'])*quantity
                priced_position['position_vol'] = parseFloat(priced_position['position_vol'])*100.0
                priced_position['pnl'] = parseFloat((priced_position['position_npv'])-node.data.vwap)*quantity


                var current_data = node.data;
                var updated_position = { ...current_data, ...priced_position }
                node.setData(updated_position);
              }
          }
          );
        } else {
          //setRepricedPositions([])
        }
          setPricingToken(undefined);
        }
      });
    }

}, [pricingToken, positions]);



  useEffect(() => {

    if ( props.positions != undefined && props.marketData != undefined && props.valuationDate !== undefined && props.userSessionVols !== undefined &&
      positionsGridRef.current !== undefined &&  positionsGridRef.current.api !== undefined && props.userCredentials !== undefined )
    {
      var positions = {}
      var vols_and_payoffs = {}

      vols_and_payoffs['position'] = []

      if (Object.keys(props.positions).length == 0)
      {
        setPositions(undefined)
        return;
      }

      Object.keys(props.positions).forEach(position_symbol => {

        var position = props.positions[position_symbol];

        position['position'] = (position['position_details']['buy_amt'] - position['position_details']['sell_amt'])
        position['vwap'] = (
            (position['position_details']['buy_avg_price'] * position['position_details']['buy_amt']) -
            (position['position_details']['sell_avg_price'] * position['position_details']['sell_amt']) )/position['position']

        var instrument_type = position['instrument_details']['type']

        if ( instrument_type === 'option' )
        {
          position['quantity'] = position['position'] * position['instrument_details']['contract_multiplier']

          var option_details = position['instrument_details']['trade_details']
          var underlying_symbol = position['instrument_details']['underlying_symbol']
          var option_request_id = uuid();

          option_details['request_id'] = option_request_id

          var expiry_date_vol =  position['instrument_details']['trade_details']['expiry_date_vol']
          var expiry_date_iso =  position['instrument_details']['trade_details']['expiry_date_iso']
          var underlying_symbol = position['instrument_details']['underlying_symbol']
          var vol_strike = position['instrument_details']['trade_details']['Strike']
          var option_type = position['instrument_details']['trade_details']['OptionType']

          if ( underlying_symbol in props.marketData)
          {
            var underlying_symbol_market_data = props.marketData[underlying_symbol]
            option_details['Underlying'] = underlying_symbol_market_data['Price']
            //position['position_npv'] = instrument_market_data['Price']
          }

          var vol = parseFloat(expiry_date_vol);
          if ( underlying_symbol in props.userSessionVols)
          {
              var interpolated_vols = props.userSessionVols[underlying_symbol]['interpolated_vols']
              var exp_date_strikes = interpolated_vols[expiry_date_iso]

              if ( exp_date_strikes != undefined )
              {
                var diviser = 1

                if ( exp_date_strikes['diviser'] !== undefined )
                  diviser = parseFloat(exp_date_strikes['diviser']);

                var exp_date_vols = {}

                if (option_type == 'call')
                  exp_date_vols = exp_date_strikes['calls']
                else
                  exp_date_vols = exp_date_strikes['puts']

                var interpol_vol_strike = (vol_strike * diviser).toFixed(1)

                if ( interpol_vol_strike in exp_date_vols )
                {
                  vol = exp_date_vols[interpol_vol_strike]
                }
              }
          }

          option_details['vol'] = vol
          option_details['symbol'] = position_symbol
          option_details['underlying_symbol'] = underlying_symbol
          option_details['valuationDate'] = format(props.valuationDate, "yyyy-MM-dd")

          if ( option_details['exercise_type'] == 'European' )
          {
             option_details['model'] = props.europeanPricingModel.value
          } else if ( option_details['exercise_type'] == 'American' )
          {
             option_details['model'] = props.americanPricingModel.value
          }

          var options_position = QuantLibHelper.get_option_termstructure( option_details ['exercise_type'], option_details )

          vols_and_payoffs['position'].push(options_position)
          position['name'] = position['instrument_details']['name']
          positions[position_symbol] = position;

        } else {  // stock

          if ( position_symbol in props.marketData)
          {
            var instrument_market_data = props.marketData[position_symbol]
            var lastest_price = instrument_market_data['Price'];
            position['name'] = position_symbol
            position['position_npv'] = lastest_price
            position['pnl'] = (lastest_price - position['vwap']) * position['position']
            console.log(instrument_market_data);
            positions[position_symbol] = position;
          }
        }


      });

      console.log(vols_and_payoffs)
      var price_request = {}

      price_request["request_id"] = uuid();
      price_request["portal"] = "OPTIONS_PORTAL"
      price_request["operation"] = "PRICE"
      price_request["business_date"] = format(props.valuationDate, "yyyy-MM-dd")

      price_request['vols_and_payoffs'] = vols_and_payoffs

      PricerHelper.submit_request(price_request, (pricingToken) => { setPricingToken(pricingToken); });

      if ( positionsGridRef.current.api.getDisplayedRowCount() < Object.keys(positions).length )
      {
        setPositions(Object.values(positions))
      } else {

          var rows_to_delete = []
          positionsGridRef.current.api.forEachNode(node =>
          {
              if (node.data == null )
                return;

              var symbol = node.data['instrument_details']['symbol'];
              var position = positions[symbol];
              console.log('Symbol : ' + symbol + ':' + position )
              if ( position == undefined )
                rows_to_delete.push(node.data)
              else if ( node.data.position !== position.position )
                node.setData(position);
          });

          rows_to_delete.forEach((deletedRow, i) => {
              positionsGridRef.current.api.updateRowData({ remove: [deletedRow] })
          });

      }
    }

  }, [props.positions, props.marketData, props.valuationDate, props.userSessionVols]);


/*
  useImperativeHandle(ref, () => ({
    update_greeks(greeks) {

        greeksGridRef.current.api.forEachNode(node =>
        {
          if (node.data == null )
            return;

          var strike = node.data.Strike;

          var strike_greeks = greeks[strike];

          if ( strike_greeks != undefined )
          {
            node.setData(strike_greeks);
            if (node.selected === true)
            {
              props.strikeSelectedCallback(node);
            }
          }
      }
      );
  },

  flash_stike(strike) {

      var selected_node = undefined;

      greeksGridRef.current.api.forEachNode(node =>
      {
        if ( selected_node === undefined && node.data.Strike == strike.data.Strike )
        {

          selected_node = node;
          node.setSelected(true);
          greeksGridRef.current.api.ensureIndexVisible(node.rowIndex);
          var updated_row = greeksGridRef.current.api.getDisplayedRowAtIndex(node.rowIndex);
          greeksGridRef.current.api.flashCells({ rowNodes: [updated_row] });

          return true;
        }
    }
    );
}

}));*/


   return (
      <Container>
      <div className="ag-theme-balham-dark">
        <Row>
          <Col style={{textAlign: 'left'}}>
              <h6>
                Positions
              </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"40vh", width: "100%", display: "inline-block", margin: "auto"}}>
            <AgGridReact
                      rowData={positions}
                      columnDefs={positionsGridColumnDefs}
                      gridOptions={positionsGridOptions}
                      ref={positionsGridRef}>
            </AgGridReact>
            </div>
          </Col>
        </Row>
        </div>
      </Container>
     )
  });

export default PositionsPanel;
