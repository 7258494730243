import React from 'react'
import Plot from 'react-plotly.js';

import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap/';

import VolSurface from './VolSurface';


const { forwardRef, useRef, useImperativeHandle } = React;

const SurfacePanel = React.forwardRef ((props, ref) => {

  const chartRef = useRef();

  const [data, setData] = useState();
  const [calls, setCalls] = useState();
  const [puts, setPuts] = useState();


  useEffect(() => {

    if ( props.interpolated_vols !== undefined && props.selectedInstrument !== undefined )
    {

      var selected_instrument = props.interpolated_vols['selected_instrument']
      var ticker = selected_instrument['ticker']
      var name = selected_instrument['Name']
      //setName(name)
      var exerciseDate = props.interpolated_vols['expiration_date']
      var interpolated_vols = props.interpolated_vols['vols'][ticker]
      var mergedStrikes = props.interpolated_vols['merged_strikes']

      if ( interpolated_vols !== undefined )
      {

          const excercise_date = Object.keys(interpolated_vols);
          if ( excercise_date.length == 0 )
            return;
          //var call_strikes =

          var surface_data = {'calls':{},'puts':{}};

          for (let exp_date in interpolated_vols) {
            console.log(`ExpDate: ${exp_date}, Value: ${interpolated_vols[exp_date]}`);
            var call_strikes = interpolated_vols[exp_date]['calls']
            var put_strikes = interpolated_vols[exp_date]['puts']

            surface_data['calls'][exp_date] = interpolated_vols[exp_date]['calls']
            surface_data['puts'][exp_date] = interpolated_vols[exp_date]['puts']

            surface_data['calls']['diviser'] = interpolated_vols[exp_date]['diviser']
            surface_data['puts']['diviser'] = interpolated_vols[exp_date]['diviser']


            //console.log(call_strikes)
          }

          setCalls(surface_data['calls'])
          setPuts(surface_data['puts'])

          console.log(surface_data)
          console.log(interpolated_vols)
      }
    };

}, [props.interpolated_vols, props.selectedInstrument]);


  return(
    <Row>
      <Col>
        <VolSurface vol_surface={calls} type='CALLS'
          setupOptionsPanel = {(option_setup) =>
            {
                props.option_side_setup(option_setup)
            }}/>
      </Col>
      <Col>
        <VolSurface vol_surface={puts} type='PUTS'
          setupOptionsPanel = {(option_setup) =>
                    {
                          props.option_side_setup(option_setup)
                    }}/>
      </Col>
    </Row>
    );
});

export default SurfacePanel;
