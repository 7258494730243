/*  This file is part of ql_rest, a free-software/open-source library
    for utilization of QuantLib over REST */

import { useEffect, useState } from 'react';
import React from 'react';
import QuantLibHelper from '../helpers/QuantLibHelper'
import PricerHelper from '../helpers/PricerHelper'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { Container, Row, Col } from 'react-bootstrap/';
import { compareAsc, format, parseISO } from 'date-fns'

import uuid from 'react-uuid'

const { forwardRef, useRef, useImperativeHandle } = React;

const ExposurePanel = React.forwardRef ((props, ref) => {

  const exposureGridRef = React.useRef();

  const [exposure, setExposure] = useState();


  const numberFormatter = (params) => {
      if (!isNaN(params.value)) return parseFloat(params.value).toFixed(2);
  };

  function compareCall(params) {
    /*if (params.data.Strike <= props.marketPrice.Price ){
      return {'textAlign': 'right', padding:'0px', color: 'rgba(75,192,192,1)'};
    } else {
      return {'textAlign': 'right', padding:'0px', color: 'rgba(75,192,192,0.7)'};
    }*/
  }

  function comparePut(params) {
    /*if (params.data.Strike >= props.marketPrice.Price ){
      return {'textAlign': 'right', padding:'0px', color: 'rgba(255, 99, 132,1)'};
    } else {
      return {'textAlign': 'right', padding:'0px', color: 'rgba(255, 99, 132,0.7)'};
    }*/
  }

  function compareCallGreeks(params) {
    /*if (params.data.Strike <= props.marketPrice.Price ){
      return {'textAlign': 'right', padding:'0px', opacity: '1'};
    } else {
      return {'textAlign': 'right', padding:'0px', opacity: '0.7'};
    }*/
  }

  function comparePutGreeks(params) {
  /*  if (params.data.Strike >= props.marketPrice.Price ){
      return {'textAlign': 'right', padding:'0px', opacity: '1'};
    } else {
      return {'textAlign': 'right', padding:'0px', opacity: '0.7'};
    }*/
  }


  function riskCellRenderer ( props ) {
    if (!isNaN(props.value)) return `${parseFloat(props.value).toFixed(2)}`;
  };

  function positionCellRenderer ( props ) {
    if (!isNaN(props.value)) return parseFloat(props.value).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  function pnlCellRenderer ( props ) {
    if (!isNaN(props.value)) return parseFloat(props.value).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  function notionalCellRenderer ( props ) {
    if (!isNaN(props.data.quantity)) return parseInt(props.data.quantity * props.data.price).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };



  const exposureGridColumnDefs = [
    { field: 'symbol', headerName: 'Symbol',  sortable: true, cellStyle: {'textAlign': 'left'}, width: '75px', pinned: 'left', resizable: true  },
    { field: 'name', headerName: 'Name',  sortable: true, flex: 1, cellStyle: {'textAlign': 'left'}, width: '150px', flex: 3, resizable: true },
    { field: 'quantity', headerName: 'Exp.Quantity',  sortable: true, flex: 1, cellStyle: {'textAlign': 'right'}, width: '75px', flex: 3, resizable: true },
    { field: 'notional', headerName: 'Exp.Notional',  sortable: true, flex: 1, cellStyle: {'textAlign': 'right'}, cellRenderer:notionalCellRenderer, width: '75px', flex: 3, resizable: true },
    { field: 'price', headerName:'Price' ,  sortable: true, cellStyle: {'textAlign': 'right'}, cellRenderer:riskCellRenderer, flex: 0, width: '75px', resizable: true },
    { field: 'pnl', headerName:'P&L' ,  sortable: true,  cellStyle: {'textAlign': 'right'}, cellRenderer:pnlCellRenderer, width: '75px', resizable: true  },
    { field: 'delta', headerName:'Delta' ,  sortable: true,  cellStyle: {'textAlign': 'right'}, cellRenderer:positionCellRenderer, flex: 0, width: '75px', resizable: true  },
    { field: 'gamma', headerName:'Gamma' ,  sortable: true, cellStyle: {'textAlign': 'right'}, cellRenderer:riskCellRenderer, flex: 0, width: '75px', hide: true, resizable: true   },
    { field: 'vega', headerName:'Vega' ,  sortable: true,  cellStyle: {'textAlign': 'right'}, cellRenderer:riskCellRenderer, flex: 0, width: '75px', resizable: true   },
    { field: 'theta', headerName:'Theta' ,  sortable: true,  cellStyle: {'textAlign': 'right'}, cellRenderer:riskCellRenderer, flex: 0, width: '75px', resizable: true   },
    { field: 'rho',  headerName:'Rho' ,  sortable: true,  cellStyle: {'textAlign': 'right'}, cellRenderer:riskCellRenderer, flex: 0, width: '75px', resizable: true   },
  ];



  const exposureGridOptions = {

    rowSelection: 'single',

    getRowHeight: (params) => 25,

    onRowSelected(event) {

      /* if (!event.node.selected || event.data.RiskFreeRate === undefined)
        return;

      props.strikeSelectedCallback(event);*/
    },

  }

  useEffect(() => {

    if ( props.selectedStrike != null )
    {
      /*greeksGridRef.current.api.ensureIndexVisible(props.selectedStrike.rowIndex);
      greeksGridRef.current.api.forEachNode( node=> {
      if ( node.rowIndex === props.selectedStrike.rowIndex ) {
        node.setSelected(true);
        }
      })*/
    }


  }, [props.selectedStrike]);



  useEffect(() => {

    if ( props.exposure !== undefined )
    {
        console.log(props.exposure);
        setExposure(props.exposure);
    }

  }, [props.exposure]);


   return (
     <Container>
      <div className="ag-theme-balham-dark">
       <Row>
         <Col style={{textAlign: 'left'}}>
             <h6>
               Exposure
             </h6>
         </Col>
       </Row>
       <Row>
         <Col>
           <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"40vh", width: "100%", display: "inline-block", margin: "auto"}}>
           <AgGridReact
                     rowData={props.exposure}
                     columnDefs={exposureGridColumnDefs}
                     gridOptions={exposureGridOptions}
                     ref={exposureGridRef}>
           </AgGridReact>
           </div>
         </Col>
       </Row>
      </div>
     </Container>
     )
  });

export default ExposurePanel;
