import React from "react";


export const SESSION_URL = "https://vps-b7780821.vps.ovh.us:9443"
export const PRICER_URL = "https://vps-b7780821.vps.ovh.us:9443"

//export const SESSION_URL = "http://localhost:8080"
//export const PRICER_URL = "http://localhost:8080"

export const CALCULATOR_COUNT = 4
export const RT_PRICE_URL = "https://vps-b7780821.vps.ovh.us:10443"
export const RISK_FREE_RATE_URL = "https://vps-48149b0f.vps.ovh.us:2443"
