import { PRICER_URL} from './Constants'


const PricerHelper = {

  submit_request: function(request, callback)
  {
      var result_out = {}

      request["portal"] = "OPTION_PORTAL";

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
      };

      fetch(PRICER_URL + '/submit_request/', requestOptions)
        .then(res => res.json())
        .then(pricingToken => {

            callback( pricingToken );
        } );

      return result_out;
  },

  check_request: function(pricingToken, callback, timeout )
  {
    if (timeout == undefined)
      timeout = 500;

    setTimeout(() => {

      if (pricingToken.url == undefined)
        return;

      if (pricingToken.state == 3 )
      {
        callback(pricingToken);
        return;
      }

      var check_results = {};
      check_results["request_id"] = pricingToken.request_id;
      check_results["url"] = pricingToken.url;
      console.log("Helper class check : " + JSON.stringify(check_results))

      const requestOptionsResults = { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(check_results) };
      fetch(PRICER_URL + '/check_request/', requestOptionsResults) .then(res => res.json()) .then(check_result => callback(check_result));

    }, timeout);
  },

  get_data: function( service, request, callback )
  {
    var result_out = {}

    request["portal"] = "OPTIONS_PORTAL";

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request)
    };

    fetch(PRICER_URL + '/' + service +'/', requestOptions)
      .then(res => res.json())
      .then( tickers => callback(tickers));

    return result_out;
  },

  get_exercise_date_vols: function ( merged_strikes_in, vols_in )
  {
    var call_vols = vols_in['calls']
    var put_vols = vols_in['puts']

    var diviser = 1

    if ( vols_in.diviser !== undefined )
      diviser = parseFloat(vols_in.diviser);

    var vols = {};

    merged_strikes_in.forEach((strike, index) => {
      var f_strike = parseFloat(strike);
      vols[f_strike] = {};
      vols[f_strike]['Strike'] = f_strike;
    });

    for ( var strike in call_vols) {
      var f_strike = parseFloat(strike/diviser);
      vols[f_strike]['call_vol'] = call_vols[strike];
    }

    for (var strike in put_vols) {
      var f_strike = parseFloat(strike/diviser);
      vols[f_strike]['put_vol'] = put_vols[strike];
    }

    //var strikes = Object.keys(vols)
    var sorted_stikes = Object.keys(vols).sort((a,b) => a-b);

    var vols_out = [];

    for (var i = 0; i < sorted_stikes.length; i++)
    {
      var strike_str = sorted_stikes[i].toString();
      var call_put_vol = vols[sorted_stikes[i]];
      vols_out.push(call_put_vol);
    }

    return vols_out;
  },

/*
  get_vols: function ( vols_in, diviser )
  {
    var vols_out = {};

    Object.keys(vols_in).forEach(exercise_date =>
    {
        var vols = vols_in[exercise_date];
        var vols_per_excercise = PricerHelper.get_exercise_date_vols(vols, diviser);
        vols_out[exercise_date] = { 'list': vols_per_excercise, 'dict': vols};
    });

    return vols_out;
  },
  */

  /*-------------- get_vols: function ( vols_in, diviser )
  {
    var call_vols = vols_in['calls']
    var put_vols = vols_in['puts']

    var vols = {};

    for ( var strike in call_vols) {
      var f_strike = parseFloat(strike/diviser);
      vols[f_strike] = {};
      vols[f_strike]['Strike'] = f_strike;
      vols[f_strike]['call_vol'] = call_vols[strike];
    }

    for (var strike in put_vols) {
      var f_strike = parseFloat(strike/diviser);
      if (!(f_strike in vols) )
      {
        vols[f_strike] = {};
        vols[f_strike]['Strike'] = f_strike;
      }

      vols[f_strike]['put_vol'] = put_vols[strike];
    }

    //var strikes = Object.keys(vols)
    var sorted_stikes = Object.keys(vols).sort((a,b) => a-b);

    var vols_out = [];

    for (var i = 0; i < sorted_stikes.length; i++)
    {
      var strike_str = sorted_stikes[i].toString();
      var call_put_vol = vols[sorted_stikes[i]];
      vols_out.push(call_put_vol);
    }

    return vols_out;
  },*/


  get_greeks: function ( pricer_greeks )
  {
    var call_greeks = pricer_greeks['call']
    var put_greeks = pricer_greeks['put']

    var greeks = {};

    for ( var strike in call_greeks) {
      var f_strike = parseFloat(call_greeks[strike]['Strike']);
      greeks[f_strike] = {};
      greeks[f_strike]['Strike'] = f_strike;
      greeks[f_strike]['call_greeks'] = call_greeks[strike];
    }

    for (var strike in put_greeks) {
      var f_strike = parseFloat(put_greeks[strike]['Strike']);
      if (!(f_strike in greeks) )
      {
        greeks[f_strike] = {};
        greeks[f_strike]['Strike'] = f_strike;
      }

      greeks[f_strike]['put_greeks'] = put_greeks[strike];
    }

    var sorted_stikes = Object.keys(greeks).sort((a,b) => a-b);

    var greeks_out = {};

    for (var i = 0; i < sorted_stikes.length; i++)
    {
      var strike_str = sorted_stikes[i].toString();
      var call_put_greeks = {}

      var call_greeks = greeks[sorted_stikes[i]]['call_greeks'];
      var put_greeks = greeks[sorted_stikes[i]]['put_greeks'];

      for (var key in call_greeks)
      {
          call_put_greeks[key] = call_greeks[key];
      }

      for (var key in put_greeks)
      {
          call_put_greeks[key] = put_greeks[key];
      }

      greeks_out[strike_str] = call_put_greeks;
    }

    for (var key in greeks_out)
    {
      greeks_out[key]['call_npv'] = parseFloat(greeks_out[key]['call_npv']).toFixed(2)
      greeks_out[key]['put_npv'] = parseFloat(greeks_out[key]['put_npv']).toFixed(2)
    }


    return greeks_out;
  },

    expiry_date_iso: function ( exerciseDateStr )
    {
      var y = exerciseDateStr.substring(0,4),
          m = parseInt(exerciseDateStr.substring(5,7)-1),
          d = exerciseDateStr.substring(8,10);

      // Add one day(Inconsistency between yahoo and quantlib expirations)
      var expiry_date = new Date(y,m,d);
      expiry_date.setDate(expiry_date.getDate() + 1)

      var expiry_date_iso = expiry_date.toLocaleString("default", { year: "numeric" }) + "-" +
       expiry_date.toLocaleString("default", { month: "2-digit" }) + "-" +
       expiry_date.toLocaleString("default", { day: "2-digit" })

       return expiry_date_iso
    },

    retrieve_exp_vols: function ( volChartData, expDate )
    {
      var symbol = volChartData.selected_instrument['ticker']
      //var expiration_date = volChartData['expiration_date']

      //if ( expiration_date === undefined )
      //  return undefined

      var symbol_vols = volChartData['vols'][symbol][expDate]

      return {'symbol': symbol, 'expiration_date': expDate, 'symbol_vols':symbol_vols}
    },


    get_merged_strikes: function ( ticker_vols )
    {
      var merged_strikes = [];

      for (let business_date in ticker_vols) {
            for (let option_type in ticker_vols[business_date]) {
                var strikes = Object.keys(ticker_vols[business_date][option_type])
                merged_strikes = [...new Set([...merged_strikes, ...strikes])].sort((a, b) => a - b);
            }
      }

      return merged_strikes.map(parseFloat)
    }


};

export default PricerHelper;
