/*  This file is part of ql_rest, a free-software/open-source library
    for utilization of QuantLib over REST */

import { useEffect, useState } from 'react';

import uuid from 'react-uuid'
import React, { createContext, useCallback } from 'react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import PricerHelper from '../helpers/PricerHelper'
import QuantLibHelper from '../helpers/QuantLibHelper'

const { forwardRef, useRef, useImperativeHandle } = React;

const VolPanel = React.forwardRef ((props, ref) => {

  const marketDataGridRef = React.useRef();
  const indexRef = React.useRef();

  const [volData, setVolData] = useState();
  //const [expDate, setExpDate] = useState();
  const [volUpdated, setVolUpdated] = useState();

  const [interpolTypes, setInterpolTypes] = useState();
  const [interpolType, setInterpolType] = useState();
  const [userSessionVols, setUserSessionVols] = useState();
  const [volPricingToken, setVolPricingToken] = useState();
  const [interpolVolData, setInterpolVolData] = useState();
  const [mergedStrikes, setMergedStrikes] = useState();
  const [marketPrice, setMarketPrice] = useState();
  const [error, setError] = useState();
  //const [activeInstrument, setActiveInstrument] = useState({ticker:''});

  const [marketDataGridColumnDefs, setMarketDataGridColumnDefs] = useState();

  function compareCall(params) {

    var in_the_money = 'rgba(75,192,192,1)';
    var out_of_the_money = 'rgba(75,192,192,0.7)';
    var font_weight = ''
    if ( props.selectedExpDate != params.colDef.expirationDate )
    {
      in_the_money = 'rgba(75,192,192,0.3)';
      out_of_the_money = 'rgba(75,192,192,0.3)';
      var font_weight = ''
    }

    if (params.data.Strike <= params.data.market_price ){
      return {'textAlign': 'right', color:in_the_money, fontWeight: font_weight};
    } else {
      return {'textAlign': 'right', color: out_of_the_money, fontWeight: font_weight};
    }
  }
  function comparePut(params) {

    var in_the_money = 'rgba(255, 99, 132,1)';
    var out_of_the_money = 'rgba(255, 99, 132,0.7)';
    var font_weight = ''

    if ( props.selectedExpDate != params.colDef.expirationDate )
    {
      in_the_money = 'rgba(255, 99, 132,0.3)';
      out_of_the_money = 'rgba(255, 99, 132,0.3)';
      var font_weight = ''
    }


    if (params.data.Strike >= params.data.market_price ){
      return {'textAlign': 'right', color: in_the_money, fontWeight: font_weight};
    } else {
      return {'textAlign': 'right', color: out_of_the_money, fontWeight: font_weight};
    }
  }

  const marketDataGridOptions = {

    getRowHeight: (params) => 25,
    rowSelection: 'single',
    suppressScrollOnNewData: true,

    onCellClicked(event) {
      props.selectedExpDateCallback(event.colDef.expirationDate)
      props.strikeSelectedCallback(event.data.Strike)
    },

    deltaRowDataMode: true,
    getRowNodeId: function (data) {
      return data.Strike
    },
  }

  useEffect(() => {
    setInterpolType( {"label": "Linear", "value": "LINEAR" })
  }, []);

  useEffect(() => {

    if ( interpolType != undefined && props.selectedInstrument!= undefined )
    {
      var symbol_vol_request = {'tickers':[props.selectedInstrument['ticker'],]}

      var results = PricerHelper.get_data('get_latest_vols', symbol_vol_request, (latest_vols) => {

            setUserSessionVols(undefined)
            setInterpolVolData(undefined)

            symbol_vol_request['tickers'].forEach((symbol, i) => {

              var exercises_dates = []
              var ticker_vols = latest_vols[symbol]['vol_data']

              Object.keys( ticker_vols ).forEach((item, i) => {
                var exercise = {};
                exercise['value'] = item;
                exercise['label'] = item;
                exercises_dates.push(exercise);
              });


              var merged_strikes = PricerHelper.get_merged_strikes(ticker_vols);

              /*
              for (let business_date in ticker_vols) {
                    for (let option_type in ticker_vols[business_date]) {
                        var strikes = Object.keys(ticker_vols[business_date][option_type])
                        merged_strikes = [...new Set([...merged_strikes, ...strikes])].sort((a, b) => a - b);
                    }
              }*/

              var user_session_vols = {}
              user_session_vols['exercise_dates'] = exercises_dates
              user_session_vols['original_vols'] = ticker_vols
              user_session_vols['interpolated_vols'] = ticker_vols
              user_session_vols['merged_strikes'] = merged_strikes
              user_session_vols['Symbol'] = props.selectedInstrument['ticker']
              user_session_vols['Interpol'] = interpolType.value

              setUserSessionVols(user_session_vols)
              return;
            });
        });
    }

  }, [interpolType, props.selectedInstrument]);

  useEffect(() => {

    if ( userSessionVols != undefined )
    {
      var price_request = {}
      setInterpolVolData(undefined)

      var request_id = uuid();
      price_request["request_id"] = request_id
      price_request["portal"] = "OPTIONS_PORTAL"
      price_request["operation"] = "VOL_CALC"

      var interpol_request =
          QuantLibHelper.get_interpolation_request(
          request_id,
          userSessionVols['original_vols'],
          userSessionVols['merged_strikes'],
          userSessionVols['Interpol'])
      price_request["request"] = {}
      price_request["request"][userSessionVols['Symbol']] = interpol_request

      PricerHelper.submit_request(price_request, (pricingToken) => {
        setVolPricingToken(pricingToken);
      });

    }

  }, [userSessionVols]);

  useEffect(() => {

    if (volPricingToken == undefined)
       return;

    PricerHelper.check_request(volPricingToken, (pricingResults) =>
    {
      if ( pricingResults.state == 1 && pricingResults['operation'] == 'VOL_CALC' )
      {
        var pricer_re_request = {};
        pricer_re_request["request_id"] = pricingResults.request_id;
        pricer_re_request["url"] = pricingResults.url;
        console.log("Re-Checking Pricing Request : " + JSON.stringify(pricer_re_request))
        setVolPricingToken(pricer_re_request);
        return
      } else if (pricingResults['operation'] == 'VOL_CALC')
      {
          setInterpolVolData(pricingResults['vols'])
      } else {
          setError(pricingResults.error);
          setPricingDisabled(false);
          setGreeksDisabled(false);
      }

        setVolPricingToken(undefined);
      }, 250);

  }, [volPricingToken]);

  useEffect(() => {

    if ( interpolVolData != undefined &&
        props.selectedInstrument != undefined &&
        props.selectedInstrument['ticker'] in interpolVolData )
    {
      const expDates = Object.keys(interpolVolData[props.selectedInstrument['ticker']])

      if ( props.selectedExpDate == undefined || !expDates.includes(props.selectedExpDate) )
      {
          var expDate = expDates[0]
          props.selectedExpDateCallback(expDate)
      }

    }

  },[interpolVolData, props.selectedInstrument]);


  useEffect(() => {

    if ( props.selectedInstrument != undefined && props.marketData != undefined && props.selectedStrike == undefined )
    {
      var market_data = props.marketData[props.selectedInstrument['ticker']]
      props.strikeSelectedCallback(market_data['Price'])
    }

  },[props.selectedInstrument, props.marketData, props.selectedStrike]);


  useEffect(() => {

    if ( volData != undefined && props.selectedStrike != undefined )
    {

      var stike_selected = false;
      marketDataGridRef.current.api.forEachNode( node=>
        {
          if ( parseFloat(node.data.Strike) >= parseFloat(props.selectedStrike) && stike_selected == false )
          {
            node.setSelected(true);
            marketDataGridRef.current.api.ensureIndexVisible(node.rowIndex);
            stike_selected = true;
          }
      })

      //var market_data = props.marketData[props.selectedInstrument['ticker']]
      //props.strikeSelectedCallback(market_data['Price'])
    }

  },[ volData, props.selectedStrike]);


  useEffect(() => {

    if ( props.selectedExpDate != undefined &&
        interpolVolData != undefined &&
        userSessionVols != undefined &&
        props.selectedInstrument != undefined &&
        props.marketData != undefined )
    {
      var ticker = props.selectedInstrument['ticker']
      var market_price = props.marketData[ticker]
      if (market_price == undefined) return

      setMarketPrice(market_price)

      var interpolated_vols = interpolVolData[ticker]
      if ( interpolated_vols == undefined )
        return

      var marketDataGridColumnDefs = [
        { field: 'Strike', cellStyle: {'textAlign': 'right', paddingRight:'10px'},
        sortable: false,  editable: true, width:'65px', pinned: "left" },
        { field: 'Symbol', cellStyle: {'textAlign': 'right', paddingRight:'10px'},
        sortable: false,  editable: true, width:'65px', pinned: "left", hide: true }
      ]

      const exercise_dates = Object.keys(interpolated_vols);

      exercise_dates.forEach((exercise_date_header, i) => {

        var vol_header = {}
        var header_name = exercise_date_header
        vol_header['headerName'] = header_name
        vol_header['children'] = [
          { field: header_name+'_call_vol', headerName:'Call',
            cellStyle: compareCall, expirationDate: header_name, option_type: 'calls', width:'65px',
            sortable: false,  editable: true,

            cellRenderer: props => {
              if (!isNaN(props.value)) return `${(props.value)|0}%`;
            }},

          {
            field: header_name+'_put_vol', headerName:'Put',
            cellStyle: comparePut, width:'65px', option_type: 'puts',
            sortable: false,  editable: true,  expirationDate: header_name,

            cellRenderer: props => {
                    if (!isNaN(props.value)) return `${(props.value)|0}%`;
          }}
        ];
        marketDataGridColumnDefs.push(vol_header)

      });

      setMarketDataGridColumnDefs(marketDataGridColumnDefs)

      //var expDate = exercise_dates[0]

      var vols = []
      userSessionVols['merged_strikes'].forEach((strike, i) => {
        var strike_row = {}
        strike_row['Strike'] = strike
        strike_row['Symbol'] = ticker
        strike_row['MarkePrice'] = market_price.Price

        exercise_dates.forEach((exercise_date, i) => {

          var diviser = interpolated_vols[exercise_date]['diviser']
          var calls = interpolated_vols[exercise_date]['calls']
          var puts = interpolated_vols[exercise_date]['puts']

          var call_vol = calls[diviser*strike]
          var put_vol = puts[diviser*strike]
          //var exp_date = props.expDateAndStrike['expDate']
          strike_row[exercise_date+'_call_vol'] = call_vol
          strike_row[exercise_date+'_put_vol'] = put_vol

          //strike_row['expDate'] = props.expDateAndStrike['expDate']

        });

        vols.push(strike_row)

      });

      setVolData(vols)
      //setExpDate(expDate)
      setVolUpdated(undefined)
    }

  }, [interpolVolData, userSessionVols, props.selectedInstrument, props.marketData, props.selectedExpDate]);


  /*
  useEffect(() => {
      if ( props.selectedExpDate !== undefined && volData !== undefined )
      {
        if ( marketDataGridRef.current.api != undefined )
        {
          let rowSelected = false;

          marketDataGridRef.current.api.forEachNode((node) => {

            if ( rowSelected == false && node.data.Strike >= props.expDateAndStrike['strikePrice'] )
            {
              rowSelected = true;
              node.setSelected(true); // Select the row
              marketDataGridRef.current.api.ensureIndexVisible(node.rowIndex,"middle"); // Scroll to row
              //props.workingPriceChangeCallback(node.data.Strike)
              return; // Stop after first match
            }

          })
      }
    }

  }, [ props.expDateAndStrike, volData ]);
  */

  useEffect(() => {
      if ( props.surfaceSelect !== undefined && volData !== undefined )
      {
          //setExpDate(props.surfaceSelect.expiration_date)
          //props.workingPriceChangeCallback(props.surfaceSelect.Strike)
          //console.log(props.surfaceSelect)
          //setExpDate(props.surfaceSelect.expirtation_date)
      }
    }, [props.surfaceSelect, volData]);


  useEffect(() => {

      if ( interpolVolData != undefined &&
          userSessionVols != undefined && props.selectedInstrument != undefined )
      {
        var vol_price_data = {}
        vol_price_data['merged_strikes'] = userSessionVols['merged_strikes']
        vol_price_data['selected_instrument'] = props.selectedInstrument

        if ( volUpdated == undefined )
        {
          vol_price_data['vols'] = interpolVolData
        } else {

          var expirtation_date = volUpdated.colDef.expirationDate;
          var option_type = volUpdated.colDef.option_type;
          var symbol = volUpdated.data.Symbol;
          var strike = volUpdated.data.Strike;
          var diviser = interpolVolData[symbol][expirtation_date]['diviser']

          var strike_adjusted = strike * diviser

          interpolVolData[symbol][expirtation_date][option_type][strike_adjusted] = parseFloat(volUpdated.newValue)
          vol_price_data['vols'] = interpolVolData
        }

        props.volDataCallback(vol_price_data)
      }

    }, [ interpolVolData, userSessionVols, props.selectedInstrument, volUpdated ]);


    /*
    useEffect(() => {

        if ( expDate != undefined )
        {
          setVolData((prevData) =>
            prevData.map((row) => ({
              ...row,
              expDate: expDate,
       }))
     );
     marketDataGridRef.current.api.refreshCells({ force: true });
      }

      }, [expDate]);
      */

   return (
      <div>
        <div className="App-Row">
            <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"35vh", width: "100%", display: "inline-block", margin: "auto", padding:"5px"}}>
            <AgGridReact
                        rowData={volData}
                        columnDefs={marketDataGridColumnDefs}
                        gridOptions={marketDataGridOptions}
                        onCellEditingStopped={ (event)  =>
                        {
                          setVolUpdated(event)
                        } }
                        onCellEditingStarted ={ (event) => {
                        } }
                        ref={marketDataGridRef}
                        >
            </AgGridReact>
            </div>
          </div>
      </div>
     )
  });

export default VolPanel;
